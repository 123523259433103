/** @jsx jsx */
import { arrayOf, shape, string } from 'prop-types'
import { Container, Heading, jsx } from 'theme-ui'

import CardMedium from '~/components/CardMedium'
import Section from '~/components/Section'
import renderRichText from '~/content/renderRichText'
import {
  ContentfulImagePropType,
  ContentfulImageType,
  ContentfulRichTextPropType,
  ContentfulRichTextType,
  LinkPropType,
  LinkType,
} from '~/types'

const DISPLAY_NAME = 'CardMediumSection'

const PROP_TYPES = {
  body: ContentfulRichTextPropType.isRequired,
  heading: string.isRequired,
  cards: arrayOf(shape({
    backgroundImage: ContentfulImagePropType,
    body: ContentfulRichTextPropType.isRequired,
    heading: string.isRequired,
    id: string,
    kicker: string,
    link: LinkPropType,
  })),
}

interface PropTypes {
  body: ContentfulRichTextType,
  elementId?: string,
  heading: string,
  cards: {
    backgroundImage: ContentfulImageType,
    body: ContentfulRichTextType,
    heading: string,
    id: string,
    kicker: string,
    link?: LinkType,
  }[],
}

const Component = ({
  body,
  cards,
  elementId,
  heading,
}: PropTypes) => (
  <Section id={elementId}>
    <Container>
      <Heading
        sx={{
          variant: 'text.heading1',
          marginBottom: 1,
        }}
      >
        {heading}
      </Heading>
      {renderRichText(body, { style: 'prospect' })}
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: [null, null, 'repeat(2, 1fr)'],
          gridAutoRows: '1fr',
          gap: 2,
          mt: 2,
        }}
      >
        {cards.map(({
          backgroundImage,
          body: cardBody,
          heading: cardHeading,
          id,
          kicker,
          link,
        }) => (
          <CardMedium
            {...{
              backgroundImage,
              body: cardBody,
              heading: cardHeading,
              key: id,
              kicker,
              link,
            }}
          />
        ))}
      </div>
    </Container>
  </Section>
)

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
