/** @jsx jsx */
import { jsx, Heading, Text } from 'theme-ui'
import { string } from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import {
  ContentfulImagePropType,
  ContentfulImageType,
  ContentfulRichTextPropType,
  ContentfulRichTextType,
  LinkPropType,
  LinkType,
} from '~/types'
import renderRichText from '~/content/renderRichText'
import IntroTransition from '~/components/IntroTransition'
import Link from '../Link'

const DISPLAY_NAME = 'CardMedium'

const PROP_TYPES = {
  backgroundImage: ContentfulImagePropType,
  body: ContentfulRichTextPropType.isRequired,
  heading: string.isRequired,
  kicker: string,
  link: LinkPropType,
}

interface PropTypes {
  backgroundImage: ContentfulImageType,
  body: ContentfulRichTextType,
  heading: string,
  kicker?: string,
  link?: LinkType,
}

const WrapLink = ({ link, children }) => link ? (
  <Link
    href={link?.href}
    to={link?.to}
    sx={{ display: 'contents' }}
  >
    {children}
  </Link>
) : children
WrapLink.propTypes = { link: LinkPropType }

const Component = ({
  heading,
  body,
  backgroundImage,
  kicker,
  link,
}: PropTypes) => (
  <div sx={{ position: 'relative' }}>
    <WrapLink link={link}>
      <GatsbyImage
        alt=""
        aria-hidden
        draggable={false}
        image={getImage(backgroundImage.localFile)}
        sx={{
          borderRadius: 'large',
          height: '100%',
        }}
      />
      <div
        sx={{
          position: 'absolute',
          borderRadius: 'large',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          padding: 2,
          background:
            'linear-gradient(#000000 0%, #000000 20%, transparent 100%)',
        }}
      >
        {kicker && (
          <Text variant="bodyUppercase">
            {kicker}
          </Text>
        )}
        <IntroTransition>
          <Heading
            sx={{
              variant: 'text.heading2',
              marginBottom: 1,
            }}
          >
            {heading}
          </Heading>
          {renderRichText(body, { style: 'prospect' })}
        </IntroTransition>
      </div>
    </WrapLink>
  </div>
)

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
